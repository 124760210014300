/* eslint-disable react/prop-types */
import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import {
  makeStyles,
} from '@material-ui/core/styles'
import { LogClick } from '../Log'

const buttonStyles = makeStyles((theme) => ({
  root: {
    background: '#F04700',
    borderRadius: 65,
    color: '#FFF',
    '&:hover': {
      background: '#F04700',
    },
  },
  label: {
    fontSize: '22px',
    fontWeight: '700',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const Button = ({ ...props }) => {
  const classes = buttonStyles()
  const { children } = props

  return (
    <MuiButton
      fullWidth
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      onClick={() => {
        LogClick()
      }}
      disableElevation
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </MuiButton>
  )
}

export default Button
