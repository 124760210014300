/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Grid, Typography, Box,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Button from './Button'
import Logo from './Logo'

import leftImage from '../static/images/left.png'
import rightImage from '../static/images/right.png'
import centerImage from '../static/images/rec.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    minHeight: '736px',
    height: '100vh',
  },
  logo: {
    marginTop: theme.spacing(5),
  },
  main: {
    position: 'absolute',
    bottom: 0,
    zIndex: 5,
  },
  textContainer: {
    textAlign: 'center',
  },
  text: {
    color: '#000',
    fontSize: '40px',
    fontWeight: 'bold',
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  gradient: {
    width: '100%',
    minHeight: '350px',
    height: '40vh',
    position: 'absolute',
    bottom: 0,
    zIndex: 3,
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 61.25%)',
  },
  right: {
    position: 'absolute',
    bottom: theme.spacing(13),
    right: '-55px',
  },
  left: {
    position: 'absolute',
    bottom: theme.spacing(15),
    left: '-30px',
  },
  center: {
    position: 'absolute',
    bottom: theme.spacing(60),
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  sideImage: {
    width: '162px',
    height: 'auto',
  },
}))

function LangdingPage({ link }) {
  const classes = useStyles()

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
      >
        <Grid
          container
          justifyContent="center"
          className={classes.container}
        >

          <Grid item xs={12} className={classes.logo}>
            <Logo link={link} />
          </Grid>

          <div className={classes.main}>
            <Grid
              container
              alignItems="center"
              direction="column"
            >
              <Grid item xs={9} sm={7} md={5} className={classes.textContainer}>
                <Typography className={classes.text}>One-tap Call Recorder App</Typography>
              </Grid>

              <Grid container item xs={10} sm={8} md={5} className={classes.buttonContainer}>
                <Button href={link}>Try For free</Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.gradient} />

          <div className={classes.left}>
            <img className={classes.sideImage} src={leftImage} alt="" />
          </div>

          <div className={classes.right}>
            <img className={classes.sideImage} src={rightImage} alt="" />
          </div>

          <div className={classes.center}>
            <img src={centerImage} alt="" />
          </div>

        </Grid>
      </Container>
    </>
  )
}

LangdingPage.propTypes = {
  link: PropTypes.string.isRequired,
}

export default LangdingPage
