import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LogClick } from '../Log'
import iconImage from '../static/images/logo.png'

const useStyles = makeStyles(() => ({
  text: {
    textAlign: 'center',
    '& > a': {
      textDecoration: 'none',
    },
    '& > a > p': {
      fontSize: '28px',
      color: '#000',
      fontWeight: '800',
      lineHeight: '32px',
    },
  },
}))

function Logo({ link }) {
  const classes = useStyles()
  const onClick = () => {
    LogClick()
  }

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
    >
      <Grid item>
        <a href={link} onClick={onClick}>
          <img widht="80" height="80" src={iconImage} alt={`${process.env.REACT_APP_TITLE}`} />
        </a>
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <a href={link} onClick={onClick}>
          <Typography>CallRecorder</Typography>
        </a>
      </Grid>
    </Grid>
  )
}

Logo.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Logo
